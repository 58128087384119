import type {
	ActiveInlineCommentsQueryType,
	TopLevelComment,
	CommentLocation,
	CommentReply,
} from '@confluence/inline-comments-queries';
import { isValidUnreadComment } from '@confluence/unread-comments';
import type { PageMode } from '@confluence/page-utils/entry-points/enums';
import type {
	ActiveCommentsQueryType,
	ResolvedCommentsQueryType,
} from '@confluence/comments-panel-queries';

import { CommentType } from '../useCommentsData';
import type { CommentData, CommentsDataMap } from '../useCommentsData';

type NormalizeCommentDataFn = (input: {
	comment: TopLevelComment;
	readCommentsListState: Set<string>;
	currentUserId: string;
	annotationsInEditorDoc?: Set<string>;
	pageMode: PageMode;
	isOpen: boolean;
	type: CommentType;
}) => CommentData;

const normalizeCommentData: NormalizeCommentDataFn = ({
	comment,
	readCommentsListState,
	currentUserId,
	annotationsInEditorDoc,
	pageMode,
	isOpen,
	type,
}) => {
	const isUnread = Boolean(
		isValidUnreadComment({
			comment,
			viewedComments: readCommentsListState,
			userId: currentUserId,
			annotationsInEditorDoc,
			pageMode: pageMode as 'view' | 'edit',
		}),
	);

	return {
		...comment,
		isUnread,
		wasRemovedByAnotherUser: false,
		isOpen,
		type,
		replies: (comment.replies as CommentReply[]).map((reply) => {
			if (!reply.reactionsSummary) {
				reply.reactionsSummary = null;
			}

			const isReplyUnread = Boolean(
				isValidUnreadComment({
					comment: reply,
					viewedComments: readCommentsListState,
					userId: currentUserId,
					annotationsInEditorDoc,
					pageMode: pageMode as 'view' | 'edit',
				}),
			);

			return {
				...reply,
				type,
				isUnread: isReplyUnread,
				wasRemovedByAnotherUser: false,
			};
		}),
	};
};

export const updateCommentsDataState = ({
	data,
	readCommentsListState,
	annotationsInEditorDoc,
	pageMode,
	currentUserId,
	updateData,
	isOpen = true,
}: {
	data: ActiveInlineCommentsQueryType | ActiveCommentsQueryType | ResolvedCommentsQueryType;
	readCommentsListState: Set<string>;
	annotationsInEditorDoc?: Set<string>;
	pageMode: PageMode;
	currentUserId: string;
	updateData: (newCommentsDataMap: CommentsDataMap) => void;
	isOpen: boolean;
}) => {
	const inlineCommentThreadsToAdd: Record<string, CommentData> = {};
	const generalCommentThreadsToAdd: Record<string, CommentData> = {};

	data?.comments?.nodes?.forEach((comment) => {
		// We only want top level comments
		if (comment && !comment.parentId) {
			if (!comment.reactionsSummary) {
				comment.reactionsSummary = null;
			}

			const commentType = comment.location.type;

			if (commentType === 'INLINE') {
				const threadKey = (comment.location as CommentLocation).inlineMarkerRef;

				if (threadKey) {
					inlineCommentThreadsToAdd[threadKey] = normalizeCommentData({
						comment,
						readCommentsListState,
						currentUserId,
						annotationsInEditorDoc,
						pageMode,
						isOpen,
						type: CommentType.INLINE,
					});
				}
			} else if (commentType === 'FOOTER') {
				const threadKey = comment.id;

				generalCommentThreadsToAdd[threadKey] = normalizeCommentData({
					comment,
					readCommentsListState,
					currentUserId,
					pageMode,
					isOpen,
					type: CommentType.GENERAL,
				});
			}
		}
	});

	updateData({
		inline: inlineCommentThreadsToAdd,
		general: generalCommentThreadsToAdd,
	});
};
